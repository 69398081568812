.navbar {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 30px;
  background-color:white;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  border-bottom: 2px solid #ddddddcb;
}

.search-container {
  flex-grow: 1;
}



